import axios from "axios"

import moment from "moment"

import * as Cookies from "js-cookie"

import { DOMAIN } from "../constants/company"

import { HOURLY_CHART_ENABLED } from "../constants/flags"

import jwt_decode from "jwt-decode"

export const TOKEN = "kinetic-token"
export const IS_LOCAL_DEV = process.env.NODE_ENV === "development"

const KINETIC_ENV = process.env.REACT_APP_KINETIC_ENV || "dev"
const STATIC_AUTH = window.KINETIC_DASHBOARD_AUTH

export const API = {
  newBackendUrl:
    process.env.REACT_APP_BACKEND_URL ||
    `https://backend.${KINETIC_ENV}.wearkinetic.com`,
  mathEngineUrl:
    process.env.REACT_APP_MATH_ENGINE_URL ||
    `https://math.${KINETIC_ENV}.wearkinetic.com`,

  authToken: localStorage.getItem("adminToken"),
  locationUID:
    Cookies.get("currentLocation") !== undefined
      ? Cookies.get("currentLocation").replace(":1", "")
      : undefined,

  getAuthHeader: () => {
    return `Bearer ${localStorage.getItem("adminToken")}`
  },

  getKeenData: (
    locationUID,
    tz,
    shift_length,
    start,
    end,
    selectedJobs = [],
    onlyFeedbackOn = false,
    onlyBaseline = false,
    compareType = "date-range"
  ) => {
    let timezone = tz

    if (start === "1970/1/1" || compareType === "baseline") {
      timezone = "UTC"
      start = "baseline"
      end = "baseline"
    }

    // we should probably refactor this so it's an either-or type of thing, allowing both to be set is weird
    if (onlyFeedbackOn && onlyBaseline) {
      console.warn(
        "Attempting to request ONLY FEEDBACK data and ONLY BASELINE data at the same time."
      )
    }

    // don't use feedbackOff if this is a baseline request
    if (onlyFeedbackOn && compareType === "baseline") {
      onlyFeedbackOn = false
    }

    if (compareType === "none") {
      return axios
        .get(
          `${
            API.mathEngineUrl
          }/data?location=${locationUID}&timezone=${timezone}&shift_length=${shift_length}&start=${"1950/1/1"}&end=${"1950/1/1"}&jobs=${
            selectedJobs ? selectedJobs.join(",") : ""
          }${onlyFeedbackOn ? "&feedbackOff=true" : ""}${
            onlyBaseline ? "&feedbackOnly=true" : ""
          }`,
          {
            headers: { Authorization: API.getAuthHeader() },
          }
        )
        .then((data) => {
          return data.data
        })
    }

    return axios
      .get(
        `${
          API.mathEngineUrl
        }/data?location=${locationUID}&timezone=${timezone}&shift_length=${shift_length}&start=${start}&end=${end}&jobs=${
          selectedJobs ? selectedJobs.join(",") : ""
        }${onlyFeedbackOn ? "&feedbackOff=true" : ""}${
          onlyBaseline ? "&feedbackOnly=true" : ""
        }`,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  getHourlyKeenData: (locationUID, timezone, shift_length, start, end) => {
    if (!HOURLY_CHART_ENABLED) {
      return new Promise((resolve, reject) => {
        reject("Disabled")
      })
    }

    return axios
      .get(
        `${API.mathEngineUrl}/data/hourly?location=${locationUID}&timezone=${timezone}&shift_length=${shift_length}&start=${start}&end=${end}`,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  getCompare: () => {
    return axios
      .get(`${API.mathEngineUrl}/compare?location=${API.locationUID}`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  getCompanies: () => {
    return axios
      .get(`${API.newBackendUrl}/v1/companies`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        const companyMap = data.data.data.reduce(function (map, obj) {
          map[obj.uid] = obj.name
          return map
        }, {})
        const dataArr = [data.data.data, companyMap]
        return dataArr
      })
      .catch((e) => ({
        data: [[], {}],
      }))
  },

  getManagers: (companyUID) => {
    return axios
      .get(`${API.newBackendUrl}/v1/managers?companyUID=${companyUID}`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((res) => {
        const data = res.data.data
        return data
      })
  },

  getCompanyLocations: (companyUID) => {
    return axios
      .get(
        `${API.newBackendUrl}/v1/admin/company/${companyUID}/locationsfeedback`,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        console.log(data)
        return { locationsList: data.data, companyUID: companyUID }
      })
  },

  adminUpdateCompanies: (companies) => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/admin/company`,
        {
          companies: companies,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data
      })
  },

  getData: (location = API.locationUID) => {
    return axios
      .get(`${API.newBackendUrl}/v1/customerdash?locationUID=${location}`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data.data[0]
      })
  },

  getAdminData: (location = API.locationUID) => {
    return axios
      .get(
        `${API.newBackendUrl}/v1/admin/customerdash?locationUID=${location}`,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        const selectData = data.data.data[0]
        let shift_length = selectData.location.location.shift_length
        if (shift_length) {
          shift_length = shift_length / 60
        }

        return {
          ...selectData,
          location: {
            ...selectData.location.location,
            productConfigName: selectData.location?.settings_package?.name,
            shift_length: shift_length,
          },
        }
      })
  },

  getLocationsWus: (location = API.locationUID) => {
    return axios
      .get(`${API.newBackendUrl}/v1/admin/locations/${location}/wus`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  getExecutiveData: (company, date) => {
    return axios
      .get(
        `${API.newBackendUrl}/v1/company/` +
          company +
          "/executiveData?end_date=" +
          date,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data.data[0]
      })
  },

  createCompany: (name, allowed_domains) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/companies`,
        {
          name: name,
          allowed_domains: allowed_domains,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data.data[0]
      })
  },

  /// -------------------------------
  //   LOCATION SETUP
  /// -------------------------------

  /**
   * @AdminFunction
   * Gets a list of all the location setups from the backend
   */

  getSelfServeList: () => {
    return axios
      .get(`${API.newBackendUrl}/v1/location_setup`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  createLocations: (locations) => {
    return axios.post(
      `${API.newBackendUrl}/v1/admin/locations`,
      {
        locations: locations,
      },
      {
        headers: { Authorization: API.getAuthHeader() },
      }
    )
  },

  getLocationSetup: () => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/location_setup`,
        {
          location_uid: API.locationUID,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  acceptSelfServiceTerms: () => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/location_setup`,
        {
          location_uid: API.locationUID,
          accept_terms: true,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  viewErgoVideoCompleted: () => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/location_setup`,
        {
          location_uid: API.locationUID,
          ergo_viewed: true,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  viewCtPageCompleted: () => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/location_setup`,
        {
          location_uid: API.locationUID,
          ct_viewed: true,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  setLocationStart: (start, l) => {
    const location = {
      uid: l.uid,
      settings_package_uid: l.settingsPackageUID,
      reporting_ergo_start: start.toISOString(),
    }

    return axios({
      method: "put",
      url: `${API.newBackendUrl}/v1/locations/program_start`,
      headers: {
        Authorization: API.getAuthHeader(),
        "Accept-Type": "application/json",
      },
      data: { locations: [location] },
    })
  },

  completeStep: (step) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/location_setup_step/` + API.locationUID,
        {
          setup_group: step.group,
          step_number: parseInt(step.number),
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  uncompleteStep: (step) => {
    return axios
      .delete(
        `${API.newBackendUrl}/v1/location_setup_step/` + API.locationUID,
        {
          data: {
            setup_group: step.group,
            step_number: parseInt(step.number),
          },
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  completeProgramSetup: () => {
    let dateObj = moment()
    let date = dateObj.format("YYYY-MM-DDT00:00:00Z")
    return axios
      .put(
        `${API.newBackendUrl}/v1/location_setup`,
        {
          location_uid: API.locationUID,
          completed_date: date,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  uploadWorkerTemplate: (csv, autoAssign = false) => {
    const data = new FormData()
    data.append("file", csv)
    data.append("autoAssign", autoAssign)

    return axios.post(
      `${API.newBackendUrl}/v1/location_setup/upload_worker_template?locationUID=${API.locationUID}`,
      data,
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          Authorization: API.getAuthHeader(),
        },
        timeout: 30000,
      }
    )
  },

  batchCreateWorkersAndJobs: (assignments) => {
    let data = {}
    data.worker_jobs = assignments
    data.location_uid = API.locationUID
    data.auto_assign = true

    //A default for feedback will be determined via feature_flag in the backend

    return axios.post(
      `${API.newBackendUrl}/v1/location_setup/workers_with_jobs`,
      data,
      {
        headers: {
          Authorization: API.getAuthHeader(),
        },
      }
    )
  },

  updateWorker: (worker) => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/employees`,
        {
          ...worker,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  getWorkerData: (worker) => {
    return axios
      .get(`${API.newBackendUrl}/v1/employees/hasData?workerUID=${worker}`, {
        headers: {
          Authorization: API.getAuthHeader(),
        },
      })
      .then((res) => {
        return res
      })
  },

  updateWorkerJobs: (workers, job) => {
    let payload = []
    payload = workers.map((item) => {
      const obj = {
        locationUID: API.locationUID,
        personUID: item,
        peopleUID: Cookies.get("email"),
        groupType: "",
      }

      if (job === null) {
        obj.groupUID = ""
      } else {
        obj.groupUID = job
      }

      return obj
    })

    console.log(payload)

    return axios
      .post(
        `${API.newBackendUrl}/v1/assignments/importEmployeeJobAssignment`,
        {
          data: payload,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data.data[0]
      })
  },

  createWorker: (worker) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/employees`,
        {
          ...worker,
          groupUID: API.locationUID,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data.data[0]
      })
  },

  createDeviceAssignment: (employee_uid, wu_uid) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/assignments/wuperson`,
        {
          assignerUID: employee_uid,
          assignToUID: employee_uid,
          wuUID: wu_uid,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((res) => res.data)
  },

  /**
   * deleteWorkers sends a list of worker IDs to the backend to delete
   *
   * @param {[string]} workers - list of worker UIDs to delete
   */
  deleteWorkers: (workers) => {
    return axios
      .delete(`${API.newBackendUrl}/v1/employees`, {
        data: {
          workers: workers,
          location: API.locationUID,
        },
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  addJobToWorker: (worker, job) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/assignments/personmemberofgroup`,
        {
          groupUID: job.uid,
          peopleUID: worker.uid,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  addWUToWorker: (worker, wu) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/assignments/wuperson`,
        {
          wuUID: wu.uid,
          assignToUID: worker.uid,
          assignerUID: worker.uid,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  /**
   * Removes devices from the workers given (bulk operation)
   *
   * @param (string) workers - list of worker UIDs to lose devoces
   */
  unassignWorkerDevices: async (workers) => {
    return axios
      .delete(`${API.newBackendUrl}/v1/assignments/workerWU`, {
        data: {
          workers: workers,
          locationUID: API.locationUID,
          assignerUID: Cookies.get("email"),
        },
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  /**
   * Adds devices to the workers given (bulk operation)
   *
   * @param (string) workers - list of worker UIDs to receive devices
   */
  assignWorkerDevices: (workers) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/assignments/workerWU`,
        {
          workers: workers,
          locationUID: API.locationUID,
          assignerUID: Cookies.get("email"),
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  createJob: (job) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/jobs`,
        { ...job, locationUID: API.locationUID },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  editJob: (job) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/jobs`,
        { ...job, locationUID: API.locationUID },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  deleteJob: (job) => {
    return axios
      .delete(`${API.newBackendUrl}/v1/jobs`, {
        data: { ...job, locationUID: API.locationUID },
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  /**
   *
   * Fetches all Devices
   */

  getWus: () => {
    return axios
      .get(`${API.newBackendUrl}/v1/admin/wus/forinventory`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((res) => {
        const data = res.data
        return data
      })
  },

  /**
   *
   * Fetches one Device
   */

  getWu: (deviceUID) => {
    return axios
      .get(`${API.newBackendUrl}/v1/wus?uid=${deviceUID}`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((res) => {
        const data = res.data.data
        return data
      })
  },

  /**
   *
   * Updates Device information
   */

  batchUpdateWus: (wus) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/admin/wus/batch`,
        {
          wus,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  moveWus: (data, locationUID, dataSource) => {
    switch (dataSource) {
      case "csv":
        const csvData = new FormData()
        csvData.append("file", data)
        csvData.append("locationUID", locationUID)

        return axios.post(
          `${API.newBackendUrl}/v1/wus/changeLocation`,
          csvData,
          {
            headers: {
              "Accept-Type": "text/csv",
              "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
              Authorization: API.getAuthHeader(),
            },
          }
        )
      case "json":
        return axios({
          method: "post",
          url: `${API.newBackendUrl}/v1/wus/changeLocation`,
          data: { locationUID: locationUID, wus: data },
          headers: {
            "Accept-Type": "application/json",
            "Content-Type": "application/json",
            Authorization: API.getAuthHeader(),
          },
        }).then((data) => {
          return data.data
        })
    }
  },

  /**
   *
   * Manager information
   */

  addManager: (manager) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/managers`,
        {
          ...manager,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  deleteManager: (manager) => {
    console.log(API.getAuthHeader())
    return axios
      .delete(`${API.newBackendUrl}/v3/user/${manager.uid}`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        console.log(data)
        return data
      })
  },

  updateManager: (manager) => {
    return axios
      .put(
        `${API.newBackendUrl}/v1/managers`,
        {
          ...manager,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  batchAddManagers: (file, company) => {
    const csvData = new FormData()
    csvData.append("file", file)
    csvData.set("company", company)

    return axios
      .post(`${API.newBackendUrl}/v1/users/batchCreate`, csvData, {
        headers: {
          "Accept-Type": "text/csv",
          "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
          Authorization: API.getAuthHeader(),
        },
      })
      .then((res) => res)
  },

  /**
   * Gets the log in method to use for the given email.
   */

  getConnectivityDevices: () => {
    return axios
      .get(`${API.newBackendUrl}/v1/assets`, {
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((res) => {
        const data = res.data
        return data
      })
  },

  updateConnectivityDevices: (locationUID, uids) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/assets/setLocation`,
        {
          locationUID: locationUID,
          uids: uids,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  /**
   * API calls for Product Configuration.
   */

  getSettingsPackages: (settingsPackageUID) => {
    return axios({
      method: "get",
      url: `${API.newBackendUrl}/v1/settingsPackages${
        settingsPackageUID ? `?uid=${settingsPackageUID}` : ""
      }`,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data
    })
  },

  adminCompanyLocationsGet: (company) => {
    return axios({
      method: "get",
      url: `${API.newBackendUrl}/v1/admin/company/${company}/locationsfeedback`,
      headers: {
        Authorization: API.getAuthHeader(),
        "Accept-Type": "application/json",
      },
    }).then((res) => {
      const data = res.data.map((l) => {
        return {
          ...l.location,
          feedback_on_count: l.feedback_on_count,
          settingsPackage: l.settings_package,
          settingsPackageName: l.settings_package_name,
          deviceCount: l.deviceCount,
          healthyDeviceCount: l.healthyDeviceCount,
          requiredVersion: l.required_version,
        }
      })
      return data
    })
  },

  adminLocationsPost: (locations_array) => {
    const locations = locations_array.map((l) => {
      // If the shift_length is greater than 24 assume that minutes
      // where used instead of hours and dont multiply by 60
      if (l.shift_length <= 24) {
        l.shift_length = l.shift_length * 60
      }
      return Object.assign(l, {
        uid: l.uid,
        settings_package_uid:
          l.settings_package_uid === "null" ? null : l.settings_package_uid,
        reporting_ergo_start: l.reportingErgoStart,
        reporting_ct_start: l.reportingCTStart,
        company_uid: l.companyUID,
        feature_flags: l.feature_flags ? l.feature_flags.join(",") : null,
        required_version: l.required_version,
      })
    })
    return axios({
      method: "post",
      url: `${API.newBackendUrl}/v1/admin/locations`,
      headers: {
        Authorization: API.getAuthHeader(),
        "Accept-Type": "application/json",
      },
      data: { locations },
    })
  },

  adminLocationsPut: (location_array) => {
    const locations = location_array.map((l) => {
      // If the shift_length is greater than 24 assume that minutes
      // where used instead of hours and dont multiply by 60

      if (l.shift_length <= 24) {
        l.shift_length = l.shift_length * 60
      }
      const new_location = Object.assign(l, {
        timezone: l.timezone,
        uid: l.uid,
        settings_package_uid:
          !l.settings_package_uid || l.settings_package_uid === "null"
            ? null
            : l.settings_package_uid,
        reporting_ergo_start: l.reportingErgoStart,
        reporting_ct_start: l.reportingCTStart,
        feature_flags: l.feature_flags ? l.feature_flags.join(",") : null,
        required_version: l.required_version,
      })

      return new_location
    })

    return axios({
      method: "put",
      url: `${API.newBackendUrl}/v1/admin/locations`,
      headers: {
        Authorization: API.getAuthHeader(),
        "Accept-Type": "application/json",
      },
      data: { locations },
    })
  },

  adminWorkersPut: (workers) => {
    return axios({
      method: "put",
      url: `${API.newBackendUrl}/v1/admin/workers`,
      headers: {
        Authorization: API.getAuthHeader(),
      },
      data: { workers: workers },
    })
  },

  createSettingsPackage: (settingsPackage) => {
    return axios({
      method: "post",
      url: `${API.newBackendUrl}/v1/assets/settingsPackages`,
      data: settingsPackage,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data
    })
  },

  updateSettingsPackage: (settingsPackage) => {
    return axios({
      method: "put",
      url: `${API.newBackendUrl}/v1/settingsPackages`,
      data: settingsPackage,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data
    })
  },

  deleteSettingsPackages: (settingsPackageUIDs) => {
    return axios
      .delete(`${API.newBackendUrl}/v1/assets/settingsPackages`, {
        data: { settings_packages: settingsPackageUIDs },
        headers: { Authorization: API.getAuthHeader() },
      })
      .then((data) => {
        return data.data
      })
  },

  /**
   * API calls for Pending Commands.
   */

  getPendingCommands: (uid) => {
    return axios({
      method: "get",
      url: `${API.newBackendUrl}/v1/pendingCommands?wuUID=${uid}`,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data.data
    })
  },

  createPendingCommands: (wus, body) => {
    return axios
      .post(
        `${API.newBackendUrl}/v1/groupedCommands`,
        {
          wus,
          body,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        return data.data
      })
  },

  deletePendingCommand: (WuUID, groupedCommandUID) => {
    return axios({
      method: "delete",
      data: { WuUID, groupedCommandUID },
      url: `${API.newBackendUrl}/v1/pendingCommands`,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data.data
    })
  },

  getExecutedCommands: (uid) => {
    return axios({
      method: "get",
      url: `${API.newBackendUrl}/v1/executedCommands?wuUID=${uid}`,
      headers: { Authorization: API.getAuthHeader() },
    }).then((data) => {
      return data.data.data
    })
  },

  /**
   * Gets the log in method to use for the given email.
   */

  async getLogInMethods(email, to) {
    let res

    try {
      res = await axios.get(
        `${API.newBackendUrl}/v1/login/methods?email=${encodeURIComponent(
          email
        )}&to=${encodeURIComponent(to)}`,
        {}
      )
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data
      }

      throw err
    }

    return res.data
  },

  /**
   * Attempts to log in the user with the given `username` and `password`
   * combination.
   */
  async login(username, password) {
    let response

    try {
      response = await axios.post(`${API.newBackendUrl}/v1/login`, {
        username,
        password,
      })
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    Cookies.set(TOKEN, response.data.token, { expires: 7 })
    Cookies.set("company", response.data.company, { expires: 7 })
    Cookies.set("email", username, { expires: 7 })

    localStorage.setItem("locations", JSON.stringify(response.data.locations))
    localStorage.setItem("email", username)
    localStorage.setItem(TOKEN, response.data.token)

    API.authToken = response.data.token

    return response.data
  },

  /**
   * Destroy the user's cookie for the sub and top level domains
   *
   *
   * @return `'OK'` on success
   * @public
   */
  logout: (refresh = true) => {
    // Remove for the top level domain
    Cookies.remove(TOKEN, { domain: DOMAIN })
    Cookies.remove("company", { domain: DOMAIN })
    Cookies.remove("email", { domain: DOMAIN })
    Cookies.remove("loctaions", { domain: DOMAIN })

    // Remove for the current subdomain
    Cookies.remove(TOKEN)
    Cookies.remove("company")
    Cookies.remove("email")
    Cookies.remove("locations")
    Cookies.remove("currentLocation")

    localStorage.removeItem("locations")
    localStorage.removeItem(TOKEN)
    localStorage.removeItem("email")

    // force a refresh on logout
    if (refresh) {
      window.location.reload()
    }

    API.authToken = undefined
  },

  /**
   * Send a request to activate an account
   *
   * @param {string} email - the email address to request activation for
   */
  async activateAccount(email) {
    let response

    try {
      response = await axios.post(`${API.newBackendUrl}/v1/activate`, {
        email: email,
        activation: true,
      })
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    Cookies.set("email", email, { expires: 7 })

    API.authToken = response.data.token

    return response.data
  },

  /**
   * Send a request to the backend for a new password
   *
   * @param {string} email - the email address to request a reset for
   */
  async requestPasswordReset(email) {
    let response

    try {
      response = await axios.post(`${API.newBackendUrl}/users/forgot_pass`, {
        email,
      })
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    return response.data
  },

  /**
   * Reset a user's password as long as they provided a valid token
   *
   * @param {string} password - the password
   * @param {string} token - the token to verify the request
   */
  async resetPassword(password, token) {
    let response

    try {
      response = await axios.put(
        `${API.newBackendUrl}/users/reset_pass`,
        {
          password,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    return response.data
  },

  /**
   * Change a user's password as long as they provide their current valid password
   *
   * @param {string} currentPassword - the current password
   * @param {string} newPassword - the new password
   */
  async changePassword(currentPassword, newPassword) {
    let response

    try {
      response = await axios.put(
        `${API.newBackendUrl}/users/change-password`,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    return response.data
  },

  /**
   * Change a user's email as long as they provide their current valid password
   *
   * @param {string} currentPassword - the current password
   * @param {string} newEmail - the new email address
   */
  async changeEmail(currentPassword, newEmail) {
    let response

    try {
      response = await axios.put(
        `${API.newBackendUrl}/users/change-email`,
        {
          currentPassword,
          newEmail,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    return response.data
  },

  /**
   * Invite a new user to the dashboard
   *
   * @param {string} newEmail - the email of the new user
   */
  async inviteUser(newEmail) {
    let response

    try {
      response = await axios.post(
        `${API.newBackendUrl}/users/invite-user`,
        {
          locationUID: API.locationUID,
          newEmail: newEmail,
        },
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }
  },

  /**
   * Gets a temporary trusted authentication ticket for using Tableau embeds.
   */
  async getTableauTicket() {
    let response

    try {
      response = await axios.post(
        `${API.newBackendUrl}/v1/login/tableau`,
        {},
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
    } catch (error) {
      if (error.response.data) {
        throw error.response.data
      } else {
        throw error
      }
    }

    return response.data
  },

  async getLocationFeatureFlags(location_id) {
    return axios.get(
      `${API.newBackendUrl}/v1/locations/${location_id}/locationFeatureFlags`,
      { headers: { Authorization: API.getAuthHeader() } }
    )
  },
  async postLocationFeatureFlags(location_uid, feature_flag_uid) {
    axios
      .post(
        `${API.newBackendUrl}/v1/admin/locationFeatureFlags`,
        {
          location_uid,
          feature_flag_uid,
        },
        { headers: { Authorization: API.getAuthHeader() } }
      )
      .catch((e) => e)
  },

  async deleteLocationFeatureFlags(location_uid, feature_flag_uid) {
    axios
      .delete(`${API.newBackendUrl}/v1/admin/locationFeatureFlags`, {
        data: {
          location_uid,
          feature_flag_uid,
        },
        headers: { Authorization: API.getAuthHeader() },
      })
      .catch((e) => e)
  },
  async getProductConfig(product_config_id) {
    return axios.get(
      `${API.newBackendUrl}/v1/admin/productConfigs/${product_config_id}`,
      { headers: { Authorization: API.getAuthHeader() } }
    )
  },
  async getProductConfigs(product_config_id) {
    return axios.get(`${API.newBackendUrl}/v1/admin/productConfigs`, {
      headers: { Authorization: API.getAuthHeader() },
    })
  },
  async getFeatureFlags() {
    return axios.get(`${API.newBackendUrl}/v1/admin/featureFlags`, {
      headers: { Authorization: API.getAuthHeader() },
    })
  },
  async putAdminLocation(location) {
    return axios.put(
      `${API.newBackendUrl}/v1/admin/locations`,
      {
        locations: [
          {
            ...location,
            shift_length: location.shift_length
              ? location.shift_length * 60
              : 480,
          },
        ],
      },
      {
        headers: {
          Authorization: API.getAuthHeader(),
          "Accept-Type": "application/json",
        },
      }
    )
  },
  async postAdminLocation(location) {
    return axios.post(
      `${API.newBackendUrl}/v1/admin/locations`,
      {
        locations: [
          {
            ...location,
            shift_length: location.shift_length
              ? location.shift_length * 60
              : 480,
          },
        ],
      },
      {
        headers: { Authorization: API.getAuthHeader() },
      }
    )
  },
  async getCompaniesNext() {
    return axios.get(`${API.newBackendUrl}/v1/companies`, {
      headers: { Authorization: API.getAuthHeader() },
    })
  },
}
