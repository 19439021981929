import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, Suspense, useState } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { ToastMessageProvider } from "../contexts/toast-message-provider"
import { Loading } from "../components/ui"

import "./_layout.scss"

const AdminMainPage = React.lazy(() => import("../pages/admin/root"))

function Layout({ children }) {
  const location = useLocation()
  const path = location.pathname.split("/")
  const auth = useAuth0()
  let baseLayout = undefined

  const [ready, readySet] = useState(false)

  useEffect(async () => {
    async function run() {
      if (auth.isLoading) return null
      if (!auth.isAuthenticated) return null
      
      readySet(true)
    }
    
    run()
  }, [auth.isLoading])

  if (!ready || (ready && !auth.isAuthenticated)) {
    return (
      <div className="breathing-container">
        <img
          className="breathing-logo"
          src={process.env.PUBLIC_URL + "/images/breathingicon.svg"}
        />
      </div>
    )
  }

  baseLayout = (
    <Suspense fallback={<Loading background={"none"} />}>
      <AdminMainPage>{children}</AdminMainPage>
    </Suspense>
  )

  return (
    <>
      <ToastMessageProvider>{baseLayout}</ToastMessageProvider>
    </>
  )
}
export default withRouter(Layout)


export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
