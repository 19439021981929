import React, { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { Icon } from "../"

export function BreadCrumbs({ breadCrumbsProps }) {
  const [breadCrumbs, setBreadCrumbs] = useState([])
  const location = useLocation()

  const passCrumbs = () => {
    breadCrumbsProps ? setBreadCrumbs(breadCrumbsProps) : setBreadCrumbs([])
  }

  useEffect(() => {
    passCrumbs()
  }, [location])

  useEffect(() => {
    passCrumbs()
  }, [breadCrumbsProps])

  const renderBreadCrumbs = () => {
    let crumbs = breadCrumbs.map((crumb, index) => {
      if (breadCrumbs.length === 1) {
        return (
          <li key={"brcumb" + index}>
            <Link to={crumb.route}>{crumb.content}</Link>
          </li>
        )
      } else {
        if (index !== breadCrumbs.length - 1) {
          if (index > 0) {
            return (
              <li className="crumb-history" key={"brcumb" + index}>
                <Icon iconClass="Chevron-right" />
                <Link to={crumb.route}>{crumb.content}</Link>
              </li>
            )
          } else {
            return (
              <li className="crumb-history" key={"brcumb" + index}>
                <Link to={crumb.route}>{crumb.content}</Link>
              </li>
            )
          }
        } else {
          return (
            <li key={"brcumb" + index}>
              <Icon iconClass="Chevron-right" />
              <p>{crumb.content}</p>
            </li>
          )
        }
      }
    })
    return crumbs
  }

  return (
    <div
      className={`breadcrumbs-container ${
        breadCrumbs && breadCrumbs.length > 0 ? "Active" : ""
      }`}
    >
      <ul
        className={`breadcrumbs ${
          breadCrumbs && breadCrumbs.length > 0 ? "Active" : ""
        }`}
      >
        {breadCrumbs && renderBreadCrumbs()}
      </ul>
    </div>
  )
}
