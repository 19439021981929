import React, { useEffect, useRef } from "react"
import { timeagoFormat as format } from "../../../.."

import { Input, Icon, Form } from "../../../../components/ui"

export function CommandsDetailModal({
  command,
  close,
  deletePendingCommandHelper,
  type,
}) {
  const _modal = useRef()
  const _modalContainer = useRef()

  useEffect(() => {
    setTimeout(() => {
      _modalContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _modal.current.classList.add("Active")
    }, 150)
    document.querySelector("html").style.overflow = "hidden"
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.querySelector("html").style.overflow = "auto"
    }
  }, [])

  const handleClickOutside = (e) => {
    if (_modal.current && !_modal.current.contains(e.target)) {
      closeModal()
    }
  }

  const closeModal = () => {
    _modal.current.classList.remove("Active")
    setTimeout(() => {
      close()
    }, 300)
  }

  return (
    <div
      className="Pending-commands-detail Modal-container"
      ref={_modalContainer}
    >
      <div
        className={`Pending-commands-detail-modal Modal ${
          type === "executed" ? "Executed-commands" : ""
        }`}
        ref={_modal}
      >
        <div className={"Close-button"} onClick={closeModal}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </div>
        <h1>{type.replace(/^\w/, (c) => c.toUpperCase())} Command</h1>
        <Form>
          {type === "pending" && (
            <>
              <Input
                mode={"View"}
                disabled
                icons
                label="Action"
                value={command?.action}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="Body"
                value={command?.body}
                textArea={true}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="Grouped Command UID"
                value={command?.groupedCommandUID}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="UID"
                value={command?.uid}
              />
            </>
          )}
          {type === "executed" && (
            <>
              <Input
                mode={"View"}
                disabled
                icons
                label="Action"
                value={command?.action}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="Body"
                value={command?.body}
                textArea={true}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="err"
                value={command?.err}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="Execution Time"
                value={format(command?.executionTime)}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="Sent At"
                value={format(command?.sentAt)}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="stderr"
                value={command?.stderr}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="stdout"
                value={command?.stdout}
              />
              <Input
                mode={"View"}
                disabled
                icons
                label="UID"
                value={command?.uid}
              />
            </>
          )}
        </Form>
        {deletePendingCommandHelper && (
          <div className="Pending-commands-action-bar">
            <div
              className="button delete"
              onClick={() => deletePendingCommandHelper(command)}
            >
              <Icon iconClass={"Delete-trash"} /> Delete
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
