import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch, useStore } from "react-redux"
import { API } from "../../../utils/api"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import Papa from "papaparse"

import { Loading, SearchBar, SimpleTable, Icon } from "../../../components/ui"
import { InventoryEditModal } from "../inventory-edit-modal"
import orderColumns from "../utils/orderColumns"
import renameColumns from "../utils/renameColumns"
import { getWus, getConnectivityDevices } from "../../../redux/actions/admin"

function DeviceImport(props) {
  const fileUploader = useRef()
  return (
    <>
      <input
        onChange={(e) => {
          const f = e.target.files[0]
          if (!f) {
            return
          }
          const reader = new FileReader(f)
          reader.onload = function (e) {
            const parsed = Papa.parse(e.target.result, { header: true })
            API.batchUpdateWus(
              parsed.data
                .filter((item) => item.uid)
                .map((item) => ({
                  uid: item.uid,
                  serial_number: item.rx_number,
                  screen_label: item.rx_number,
                }))
            )
              .then(() => {
                alert("Success!")
                props.onChange && props.onChange()
              })
              .catch(() => alert("Error"))
          }
          reader.readAsText(f)
        }}
        type="file"
        ref={fileUploader}
        style={{ display: "none" }}
      />
      <div className="Action-menu-parent">
        <div className="Action-menu">
          <div
            onClick={() => {
              fileUploader.current.click()
            }}
            className="add-button upload button secondary"
          >
            <Icon iconClass={"Upload"} />
            IMPORT DEVICES
          </div>
        </div>
      </div>
    </>
  )
}

export default function InventoryCompanyListingPage() {
  const { companies } = useSelector((state) => ({
    companies: state.admin.companyList,
  }))
  const [filteredRows, setFilteredRows] = useState([])
  const [rows, setRows] = useState()
  const [deviceData, setDeviceData] = useState()
  const [hotspotData, setHotspotData] = useState()
  const [routerData, setRouterData] = useState()
  const [dockData, setDockData] = useState()
  const [activeTab, setActiveTab] = useState("")
  const [render, setRender] = useState(false)
  const dispatch = useDispatch()
  const store = useStore()
  const [editModalToggle, setEditModalToggle] = useState(false)

  const cleanData = () => {
    if (companies) {
      return companies.map((company) => {
        return {
          company: company.name,
          clickableRow: `/inventory/${company.companyUID}`,
        }
      })
    }
  }

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows([])
  }

  useEffect(() => {
    updateActiveTab()
  }, [])

  async function getConnectivityDevicesHelper() {
    let routers = []
    let simcards = []
    let docks = []

    await getConnectivityDevices(dispatch)
    store.getState().admin.connectivityDevices.map((i) => {
      const tags = Object.entries(i.tags)
      tags.map((t) => {
        i[t[0]] = t[1]
      })
      if (i.tags.type === "router") {
        routers.push(i)
      } else if (i.tags.type === "sim") {
        simcards.push(i)
      } else if (i.tags.type === "dock") {
        docks.push(i)
      }
    })
    setHotspotData(simcards)
    setRouterData(routers)
    setDockData(docks)
  }

  useEffect(() => {
    getConnectivityDevicesHelper()
  }, [])

  async function getWusHelper() {
    let wus = await getWus()
    console.log(wus[0])
    const wusData = wus.map((device) => {
      return {
        sku: device.wu.serial_number || "",
        uid: device.wu.uid || "",
        company_name: device.company_name || "",
        location_uid: device.wu.location_uid || "",
        worker_uid: device.worker_uid || "",
        screen_label: device.wu.screen_label || "",
        version: device.wu.version || "",
        status: device.wu.status || "",
        last_pinged: device.wu.last_push_wu_time || "",
        clickableRow: `/inventory/devices/${device.wu.uid}`,
      }
    })
    setDeviceData(wusData)
  }

  useEffect(() => {
    activeTab === "devices" && getWusHelper()
  }, [activeTab])

  useEffect(() => {
    setRows(Object.values(cleanData(companies)))
  }, [companies])

  useEffect(() => {
    rows && setRender(true)
  }, [rows])

  const updateActiveTab = (tab) => {
    if (tab) {
      setActiveTab(tab)
    } else if (window.location.href.split("#")[1]) {
      setActiveTab(window.location.href.split("#")[1])
    } else {
      setActiveTab("companies")
    }
    setFilteredRows([])
  }

  const editModalHelper = () => {
    setEditModalToggle(true)
  }

  const editModal = (title) => {
    return (
      <InventoryEditModal
        category={`${activeTab}`}
        close={setEditModalToggle}
        action={"Move"}
      />
    )
  }

  function data() {
    if (activeTab === "companies") {
      return rows
    }
    if (activeTab === "devices" && !!deviceData) {
      return deviceData
    }
    if (activeTab === "simcards") {
      return hotspotData
    }
    if (activeTab === "routers") {
      return routerData
    }
    if (activeTab === "docks") {
      return dockData
    }
  }

  const table = () => {
    return (
      <div className="admin wrapper Inventory-manager">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Inventory Manager • Kinetic</title>
        </Helmet>
        {editModalToggle && editModal()}
        <div className="admin container">
          <div className="main-content">
            <h1 className="main-heading">Inventory Manager</h1>
            <div className="main-heading-parent">
              <a
                className={`main-heading tab ${
                  activeTab === "companies" ? "active" : ""
                }`}
                href="#companies"
                onClick={() => updateActiveTab("companies")}
              >
                Companies
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "devices" ? "active" : ""
                }`}
                href="#devices"
                onClick={() => updateActiveTab("devices")}
              >
                Devices
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "simcards" ? "active" : ""
                }`}
                href="#simcards"
                onClick={() => updateActiveTab("simcards")}
              >
                SIM Cards
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "routers" ? "active" : ""
                }`}
                href="#routers"
                onClick={() => updateActiveTab("routers")}
              >
                Routers
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "docks" ? "active" : ""
                }`}
                href="#docks"
                onClick={() => updateActiveTab("docks")}
              >
                Docks
              </a>
              <div
                className={`main-heading-tab-slider ${activeTab} ${
                  render ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="search-and-action-parent">
              <SearchBar
                placeholder={`Search ${activeTab}`}
                handleSearch={onFilterData}
                data={data(activeTab)}
                clearFilterData={clearFilterData}
              />

              {activeTab === "devices" && (
                <DeviceImport onChange={() => getWusHelper()} />
              )}
            </div>
            <section className="table-container">
              {render && rows.length > 0 && activeTab === "companies" ? (
                <SimpleTable
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : rows
                      ? rows
                      : [{ noData: "No Data" }]
                  }
                  ignoreKeys={["UID"]}
                />
              ) : activeTab === "devices" &&
                deviceData &&
                deviceData.length > 0 ? (
                <SimpleTable
                  ignoreKeys={[
                    "id",
                    "ExecutedCommands",
                    "vibration",
                    "thingUID",
                    "battery",
                    "key",
                    "lastSSID",
                    "docked",
                    "lastDocked",
                    "locked",
                    "edison",
                    "last_docked",
                    "lastPushServerTime",
                    "PendingCommands",
                    "company",
                    "notes",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : deviceData
                      ? deviceData
                      : [{ noData: "No Data" }]
                  }
                />
              ) : activeTab === "simcards" &&
                hotspotData &&
                hotspotData.length > 0 ? (
                <SimpleTable
                  ignoreKeys={[
                    "createdAt",
                    "locationChangedAt",
                    "tags",
                    "type",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : hotspotData
                      ? hotspotData
                      : [{ noData: "No Data" }]
                  }
                  checkbox={true}
                  checkboxActionLabel={"Move"}
                  editCallback={editModalHelper}
                  category={"simcards"}
                  checkboxKey={"uid"}
                />
              ) : activeTab === "routers" &&
                routerData &&
                routerData.length > 0 ? (
                <SimpleTable
                  ignoreKeys={[
                    "createdAt",
                    "locationChangedAt",
                    "tags",
                    "type",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : routerData
                      ? routerData
                      : [{ noData: "No Data" }]
                  }
                  checkbox={true}
                  checkboxActionLabel={"Move"}
                  editCallback={editModalHelper}
                  category={"routers"}
                  checkboxKey={"uid"}
                />
              ) : activeTab === "docks" && dockData && dockData.length > 0 ? (
                <SimpleTable
                  ignoreKeys={[
                    "createdAt",
                    "locationChangedAt",
                    "tags",
                    "type",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : dockData
                      ? dockData
                      : [{ noData: "No Data" }]
                  }
                  checkbox={true}
                  checkboxActionLabel={"Move"}
                  editCallback={editModalHelper}
                  category={"docks"}
                  checkboxKey={"uid"}
                />
              ) : (
                <Loading
                  background="none"
                  positionRelative={true}
                  height={"500px"}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    )
  }

  return <>{!render ? <Loading background="none" /> : table()}</>
}
