import React, { useEffect, useReducer, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch, useStore } from "react-redux"
import { WizardBar, CheckboxItem, DropDownSelect } from "../../../components/ui"
import {
  Form,
  Input,
  Loading,
  AssignmentLists,
  Icon,
  Button2,
} from "../../../components/ui"
import {
  setCurrentCompany,
  getManagerList,
  saveManager,
  deleteManager,
  setCurrentLocation,
} from "../../../redux/actions/admin"

import {
  validateEmailAddress,
  validatingEmailDomain,
  setMode,
} from "../../utils"

import { useBreadCrumbsContext, useSidePanelContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        manager: {
          ...state.manager,
          [action.key]: action.value,
        },
        locationChange: action.change,
      }
    case "SUBSCRIPTION_STATUSES":
      return {
        ...state,
        user: {
          ...state.user,
          [action.key]: action.value,
        },
        subscriptionChange: action.change,
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
  manager: {
    locations: "",
  },
  user: {
    reporting_location_ergo: false,
    reporting_company_ergo: false,
  },
}

export default function ManagerDetailPage() {
  const {
    selectedCompany,
    managers,
    companyMap,
    locations,
    companies,
    location,
  } = useSelector((state) => ({
    selectedCompany: state.admin.selectedCompany,
    managers: state.admin.managerList,
    companyMap: state.admin.companyMap,
    locations: state.admin.locationsList,
    companies: state.admin.companyList,
    location: state.admin.locationData,
  }))
  const history = useNavigate()
  const [state, localDispatch] = useReducer(reducer, initialState)
  const params = useParams()
  const dispatch = useDispatch()
  const [render, setRender] = useState(false)
  const [manager, setManager] = useState()
  const [assignedLocationsOptions, setAssignedLocationsOptions] = useState()
  const assignedLocationsLength = assignedLocationsOptions
    ? assignedLocationsOptions.length
    : 0
  const [unassignedLocationsOptions, setUnassignedLocationsOptions] = useState()
  const [validEmailAddress, setValidEmailAddress] = useState()
  const [emailMatchingDomains, setEmailMatchingDomains] = useState(false)
  const company = companies
    ? companies.filter((c) => c.uid === selectedCompany)[0]
    : {}
  const allowedDomains = company ? company?.allowed_domains : null
  const passBreadCrumbs = useBreadCrumbsContext()
  const [activeTab, setActiveTab] = useState()
  const renderSidePanel = useSidePanelContext()
  const store = useStore()
  const [companySet, setCompanySet] = useState(false)
  const [changed, setChanged] = useState(false)

  const [deleteConfirmed, setDeleteConfirmed] = useState(false)

  useEffect(() => {

    if (!selectedCompany || selectedCompany !== params.companyid) {
      setCurrentCompany(params.companyid, dispatch)
      getManagerList(params.companyid, dispatch)
    }

    if (!location.location) {
      setCurrentLocation(params.locationid, dispatch)
      store.subscribe(handleChangesToStore)
    }
    const unsubscribe = store.subscribe(handleChangesToStore)
    updateActiveTab()
    return () => {
      unsubscribe()
    }
  }, [])

  const handleChangesToStore = () => {
    if (
      !companySet &&
      store.getState().admin.locationData?.location?.company_uid
    )
      setCompanySet(true)
  }
  useEffect(() => {
    if (companySet) {
      setCurrentCompany(
        store.getState().admin.locationData.location.company_uid,
        dispatch
      )
      getManagerList(
        store.getState().admin.locationData.location.company_uid,
        dispatch
      )
    }
  }, [companySet])

  useEffect(() => {
    if (managers) {
      managers.filter((i) => {
        if (i.uid === params.managerid) {
          setManager(i)
        }
      })
    }
  }, [managers])

  useEffect(() => {
    if (manager) {
      populateManagerState()
      if (manager.email) {
        setValidEmailAddress(
          validateEmailAddress(manager.email, allowedDomains)
        )
      }
    } else {
      setMode(localDispatch)
      let unassignedLocations = []

      locations.map((i) => {
        let optionObj = { location: i.name, locationUID: i.uid }
        unassignedLocations.push(optionObj)
      })
      setUnassignedLocationsOptions(unassignedLocations)
      setRender(true)
    }
  }, [manager, locations])

  useEffect(() => {
    if (manager) {
      if (manager.email) {
        setEmailMatchingDomains(allowedDomains ? validatingEmailDomain(manager.email, allowedDomains) : false)
      }
    }
  }, [company])

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const viewMode = search.get("mode") === "View"
    if (viewMode) {
      localDispatch({ type: "MODE", value: "View" })
    }
  }, [window.location.search])

  //build options for assignment list component
  useEffect(() => {
    let assignedLocations = []
    let unassignedLocations = []
    if (state?.manager?.locations) {
      locations.map((i) => {
        if (state.manager.locations.includes(i.uid)) {
          let optionObj = { location: i.name, locationUID: i.uid }
          assignedLocations.push(optionObj)
        } else {
          let optionObj = { location: i.name, locationUID: i.uid }
          unassignedLocations.push(optionObj)
        }
      })
    }
    if (manager) {
      setUnassignedLocationsOptions(unassignedLocations)
    }
    setAssignedLocationsOptions(assignedLocations)
  }, [state, locations])

  const delete_manager = async() => {
    if (!deleteConfirmed) {
      setDeleteConfirmed(true)
      return
    }
    await deleteManager({manager: state.manager, companyUID: selectedCompany, dispatch})
    navigate(`/companies/${selectedCompany}#managers`)
  }

  const populateManagerState = () => {
    delete manager.groupUID
    for (let [key, value] of Object.entries(manager)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const handleInput = (e, field) => {
    const value = field === "superuser" ? false : e.target.value
    field === "email" &&
      setValidEmailAddress(validateEmailAddress(value, allowedDomains))
    field === "email" &&
      setEmailMatchingDomains(validatingEmailDomain(value, allowedDomains))
    localDispatch({ type: "POPULATE", key: field, value: value })
  }

  const addLocations = (value) => {
    localDispatch({
      type: "POPULATE",
      key: "locations",
      value: [...state.manager.locations, ...value],
      change: true,
    })
    setManager(true)
  }
  const removeLocations = (value) => {
    let arrCopy = state.manager.locations
    value.map((i) => {
      const index = arrCopy.indexOf(i)
      if (index > -1) {
        arrCopy.splice(index, 1)
      }
    })
    localDispatch({
      type: "POPULATE",
      key: "locations",
      value: arrCopy,
      change: true,
    })
  }

  const updateActiveTab = (tab) => {
    const _location = window.location.href.split("/")
    if (tab) {
      setActiveTab(tab)
    } else if (window.location.href.split("#")[1]) {
      setActiveTab(window.location.href.split("#")[1])
    } else if (_location[_location.length - 1] === "add") {
      setActiveTab("add")
    } else {
      setActiveTab("locations")
    }
  }

  const form = () => {
    return (
      <div className="admin wrapper Manager-detail-page">
        {render && state.mode !== "Add" && state.mode !== "Edit"
          ? renderSidePanel("MANAGER")
          : renderSidePanel()}
        <main className="container">
          {params?.locationid
            ? passBreadCrumbs([
                {
                  content: <Icon iconClass={"Skyline"} />,
                  route: "/companies",
                },
                {
                  content: companyMap && companyMap[selectedCompany],
                  route: `/companies/${selectedCompany}/`,
                },
                {
                  content: params && params.locationid,
                  route: `/locations/${params.locationid}`,
                },
                {
                  content:
                    state.mode === "Edit"
                      ? state.mode + " Manager"
                      : state.manager?.name
                      ? state.manager.name
                      : "Manager Profile",
                  route: "",
                },
              ])
            : passBreadCrumbs([
                {
                  content: <Icon iconClass={"Skyline"} />,
                  route: "/companies",
                },
                {
                  content: companyMap && companyMap[selectedCompany],
                  route: `/companies/${selectedCompany}/`,
                },
                {
                  content:
                    state.mode !== "View"
                      ? state.mode + " Manager"
                      : state.manager?.name
                      ? state.manager.name
                      : "Manager Profile",
                  route: "",
                },
              ])}
          <div
            className={`main-content ${
              state?.mode === "Add" ? "detail-page" : ""
            }`}
          >
            {state.mode !== "Add" ? (
              <div className="main-heading-parent">
                <a
                  className={`main-heading tab ${
                    activeTab === "locations" ? "active" : ""
                  }`}
                  href="#locations"
                  onClick={() => updateActiveTab("locations")}
                >
                  Locations
                </a>
                <a
                  className={`main-heading tab main-heading--transparent ${
                    activeTab === "subscriptions" ? "active" : ""
                  }`}
                  href="#subscriptions"
                >
                  Subscriptions
                </a>
                <div
                  className={`main-heading-tab-slider ${activeTab} ${
                    render ? "active" : ""
                  }`}
                ></div>
              </div>
            ) : (
              <h2 className="main-heading">Add Manager</h2>
            )}
            {(state.mode === "Edit" || state.mode === "Add") && (
              <Form>
                <div className="manager-form-container">
                  <div className="manager-container">
                    <Input
                      mode={state.mode}
                      label="Name"
                      value={state.manager?.name}
                      onChange={(e) => handleInput(e, "name")}
                    />
                    <DropDownSelect
                      mode={state.mode}
                      label="Role"
                      options={[
                        { display: "Manager", value: "manager" },
                        { display: "Reset", value: "reset" },
                      ]}
                      val={state?.manager?.role}
                      name="Role"
                      nullOption={false}
                      onKeyUp={(e) => handleInput(e, "role")}
                      onChange={(e) => handleInput(e, "role")}
                    />
                  </div>
                  <div className="manager-container">
                    <Input
                      required={true}
                      mode={state.mode}
                      label="Email"
                      validatation={false}
                      validated={validEmailAddress}
                      value={state.manager?.email}
                      disabled={
                        allowedDomains == null || allowedDomains.length < 1
                      }
                      placeholder={
                        allowedDomains == null || allowedDomains.length < 1
                          ? "Must add whitelisted domains below"
                          : ""
                      }
                      onChange={(e) => handleInput(e, "email")}
                    >
                      {state.mode !== "View" && (
                        <>
                          {!emailMatchingDomains && (
                            <p className={"error-note"}>
                              Non-whitelisted email domain
                            </p>
                          )}
                          <p>
                            Must be one of your company's{" "}
                            <a
                              rel="noreferrer"
                              href={"/companies/" + selectedCompany + "/edit"}
                            >
                              whitelisted domains
                            </a>
                            .
                          </p>
                        </>
                      )}
                    </Input>
                  </div>
                </div>
              </Form>
            )}
            {(activeTab === "add" || activeTab === "locations") && (
              <div className="manager-assign">
                <AssignmentLists
                  onChange={() => setChanged(true)}
                  unassigned={unassignedLocationsOptions}
                  assigned={assignedLocationsOptions}
                  title1={"Unassigned Locations"}
                  title2={"Assigned Locations"}
                  firstKey={"location"}
                  secondKey={"locationUID"}
                  addItems={addLocations}
                  removeItems={removeLocations}
                  mode={"Edit"}
                  placeholder={"Must have at least one assigned location"}
                />
              </div>
            )}
            {(state.mode !== "View" || (state.mode === "View" && changed)) && (
              <div style={{display: "flex"}}>
                <Button2 onClick={() => delete_manager()} margin_top={"20px"} variant={"danger"}>
                  {!deleteConfirmed
                   ? "Delete Manager"
                   : "Click to confirm delete"
                  }
                </Button2>
                <WizardBar
                  mode={"Edit"}
                  setMode={(mode) => localDispatch({ type: "MODE", value: mode })}
                  category={"Managers"}
                  backToRoute={
                    params?.locationid
                      ? `/locations/${params.locationid}#managers`
                      : `/companies/${selectedCompany}#managers`
                  }
                  apiCall={saveManager}
                  apiArgs={{
                    manager: state.manager,
                    companyUID: selectedCompany,
                    mode: state.mode === "View" ? "Edit" : state.mode,
                    subscriptions: state.user,
                    dispatch,
                  }}
                  redirectRoute={`/companies/${selectedCompany}/managers/${params.managerid}?mode=View`}
                  disabled={!emailMatchingDomains}
                  header={"Confirm"}
                  promptMessage={
                    state.mode === "Add"
                      ? "Do you want to add a new manager?"
                      : "Do you want to save your changes?"
                  }
                  buttons={() => {
                    if (state.mode === "View") {
                      window.location.reload()
                    }
                    if (state.mode === "Edit") {
                      setChanged(false)
                      navigate(
                        `/companies/${selectedCompany}/managers/${params.managerid}?mode=View`
                      )
                    }
                    if (state.mode === "Add") {
                      navigate(`/companies/${selectedCompany}#managers`)
                    }
                  }}
                  saveActions={[() => setChanged(false)]}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    )
  }

  return (
    <>
      {render && form()}
      {!render && <Loading background={"none"} />}
    </>
  )
}
