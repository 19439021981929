import "../styles.css"

import React from "react"
import { history } from "../../../index"

/**
 * Builds a clickable link that manages the user's location with `history`
 *
 * @prop href - the route to navigate to (if the href is "back" it uses history.goBack instead)
 * @prop linkStyle - the way to render the link (`text-link` [default], `button`, or `block`)
 * @prop onClick - a function to run when the link is clicked
 * @prop disabled (bool) - if true, short circuits all click events on the link
 */
export class Link extends React.Component {
  render() {
    const {
      href,
      children,
      linkStyle,
      onClick,
      disabled,
      hidden,
      className,
    } = this.props

    return (
      <div
        className={`${linkStyle || "text-link"} ${disabled ? "disabled" : ""} ${
          hidden ? "hidden" : ""
        } ${className ? className : ""}`}
        onClick={(e) => {
          if (disabled) {
            return
          }

          if (onClick) {
            onClick(e)
          }

          if (href !== "back") {
            navigate(`/${href}`)
          } else {
            history.goBack()
          }
        }}
      >
        {children}
      </div>
    )
  }
}
