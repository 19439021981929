import Cookies from "js-cookie"

import { history } from "../.."
import { getResumeSetupURL } from "../../utils"
import { API, TOKEN } from "../../utils/api"
import {
  isSelfServe,
  isLocationSelfServe,
  isContactTracing,
  hideErgo,
} from "../reducers/user"

export const SET_EMAIL = "user/SET_EMAIL"
export const SET_CURRENT_LOCATION = "user/SET_CURRENT_LOCATION"

export const LOGGED_IN = "user/LOGGED_IN"
export const LOGGED_OUT = "user/LOGGED_OUT"

export const ACTIVATE_ACCOUNT = "user/ACTIVATE_ACCOUNT"

export function setEmail(email, dispatch) {
  dispatch({ type: SET_EMAIL, email })
}

export function setCurrentLocation(uid, dispatch) {
  Cookies.set("currentLocation", uid)
  API.locationUID = uid

  dispatch({ type: SET_CURRENT_LOCATION, uid })

  //This was here to fix a memory leak in the explore page
  //However it causes the location selection page to keep repeating after selection
  //A deeper dive will need to be done to fix the memory leak until the new tableu system is in production.\
  //window.location.reload();
}

export async function getLogInMethods(email, to, dispatch) {
  setEmail(email, dispatch)

  return await API.getLogInMethods(email, to)
}

export async function logIn(email, password, dispatch) {
  setEmail(email, dispatch)

  const data = await API.login(email, password)

  setLoggedIn(email, data, dispatch)

  return data
}

export function setLoggedIn(email, data, dispatch) {
  Cookies.set(TOKEN, data.token, { expires: 7 })
  Cookies.set("company", data.company, { expires: 7 })
  Cookies.set("email", email, { expires: 7 })

  localStorage.setItem("locations", JSON.stringify(data.locations))
  localStorage.setItem("email", email)
  localStorage.setItem(TOKEN, data.token)

  API.authToken = data.token

  dispatch({ type: LOGGED_IN, email, data })

  if (data.locations.length === 1) {
    setCurrentLocation(data.locations[0].uid, dispatch)
  }
}

export function logOut(dispatch) {
  API.logout()

  dispatch({ type: LOGGED_OUT })
}

export async function activateAccount(email, dispatch) {
  setEmail(email, dispatch)

  const data = await API.activateAccount(email)

  dispatch({ type: ACTIVATE_ACCOUNT, data })
}

export async function requestPasswordReset(email, dispatch) {
  setEmail(email, dispatch)

  await API.requestPasswordReset(email)
}

export async function resetPassword(email, password, token, dispatch) {
  setEmail(email, dispatch)

  await API.resetPassword(password, token)
}

/** Performs the initial redirect after logging in and setting a location. */
export async function initialRedirectTo(to, method) {
  const pushState = history[method || "push"].bind(history)

  if (!to) {
    to = "/explore"
  }

  const { user } = window.store.getState()
  const selfServe = isSelfServe(user)

  // If self serve information could not be determined, user has not yet
  // selected a location.

  if (selfServe === undefined) {
    pushState(`/select-location?to=${encodeURIComponent(to)}`)
    return
  }

  // If self - serve, maybe redirect to certain pages:
  //   if (selfServe && false) {
  //     const locations = JSON.parse(localStorage.getItem("locations"))

  //     let locationSetup

  //     if (API.locationUID) {
  //       locationSetup = await API.getLocationSetup()
  //     }

  //     if (locationSetup && locations.length) {
  //       const locations = JSON.parse(localStorage.getItem("locations"))

  //       if (locations.length) {
  //         const location = locations.filter(
  //           (loc) => loc.uid === locationSetup.location_uid
  //         )[0]

  //         if (!locationSetup.completed) {
  //           if (!locationSetup.accept_terms) {
  //             // if they have not accepted terms, go there
  //             to = "/setup/terms-and-conditions"
  //           } else {
  //             if (isContactTracing(user) && hideErgo(user)) {
  //               to = "/your-program"
  //             } else {
  //               // get the next step the user should go to and redirect there
  //               to = getResumeSetupURL(locationSetup)
  //             }
  //           }
  //         } else if (!location?.reporting_ergo_start) {
  //           // if they did not set a deployment date, go to workforce
  //           to = "/your-program"
  //         }
  //       }
  //     }
  //   }

  // go to the attempted page (default is the program page)
  navigate("/explore")
  pushState(to)
}
