import React from "react"
import { Icon } from "../"
import { useNavigate } from "react-router-dom"

export function BackButton({ label, backToRoute }) {
  const navigate = useNavigate()

  return (
    <div className="Back-button" onClick={() => navigate(backToRoute)}>
      <Icon iconClass={"Arrow-left"} size={"1.2rem"} />
      <i className="Back-button-label">{label}</i>
    </div>
  )
}
