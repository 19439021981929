import React, { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useParams, useNavigate } from "react-router-dom"
import Moment from "react-moment"
import { useSelector, useDispatch, useStore } from "react-redux"
import { Helmet } from "react-helmet"

import { ListBox, Loading, Icon, BackButton } from "../../../components/ui"
import DropZone from "./drop-zone"

import {
  setCurrentLocation,
  getCompanyList,
  setCurrentCompany,
} from "../../../redux/actions/admin"
import { csvUpload } from "./api/api"

import { useBreadCrumbsContext, useToastContext } from "../../../hooks"

const KINETIC_ENV = process.env["REACT_APP_KINETIC_ENV"] || "dev"

const KINETIC_BACKEND_URL = KINETIC_ENV != "stage" && KINETIC_ENV != "prod" ? "https://backend2.dev.wearkinetic.com" : `https://backend.${KINETIC_ENV}.wearkinetic.com`

export default function CSVUploadPage() {
  const { companyUID, selectedLocation, companyMap } = useSelector((state) => ({
    companyUID: state.admin.selectedCompany
      ? state.admin.selectedCompany
      : state.admin?.locationData?.location?.company_uid,
    selectedLocation: state.admin.selectedLocation,
    companyMap: state.admin.companyMap,
  }))
  const [loading, setLoading] = useState(false)
  const [autoAssign, setAutoAssign] = useState(false)
  const [stagedFile, setStagedFile] = useState(null)
  const params = useParams()
  const [message, setMessage] = useState("")
  const [errorState, setErrorState] = useState(false)
  const [chooseLocation, setChooseLocation] = useState(false)
  const [render, setRender] = useState(false)
  const [category, setCategory] = useState("")
  const [mode, setMode] = useState("")
  const dispatch = useDispatch()
  const store = useStore()
  let navigate = useNavigate()
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    inputRef,
    isDragAccept,
  } = useDropzone({
    multiple: false,
    maxSize: 2097152,
  })
  const [templateDownloadLink, setTemplateDownloadLink] = useState("")
  const [location, setLocation] = useState("")
  const [status, setStatus] = useState("idle")
  const addToastMessage = useToastContext()

  const companyCategories = ["Locations", "Managers", "Devices"]
  const backToRoutes = {
    Locations: `/companies/${companyUID}`,
    Managers: `/companies/${companyUID}#managers`,
    Devices: selectedLocation
      ? `/locations/${selectedLocation}#devices`
      : `/companies/${companyUID}`,
    Employees: `/locations/${selectedLocation}#employees`,
  }
  const passBreadCrumbs = useBreadCrumbsContext()

  async function getCompanies() {
    await getCompanyList(dispatch)
    setChooseLocation(true)
  }

  //check for companyid, if not found check for locationid or else set company
  useEffect(() => {
    if (companyUID) {
      setRender(true)
    } else if (!companyUID && !params.locationid) {
      getCompanies()
    } else if (!selectedLocation && params.locationid) {
      setCurrentLocation(params.locationid, dispatch)
      setCompany(companyUID)
    }
    getCategory()
  }, [])

  useEffect(() => {
    if (companyUID) {
      setCompany(companyUID)
      getCategory()
    }
  }, [companyUID])

  useEffect(() => {
    const accepted = acceptedFiles[0]
    if (accepted) {
      if (accepted.size > 2000000) {
        setStatus("max")
        handleRemoveFile()
      } else {
        setStatus("accepted")
        setStagedFile(acceptedFiles[0])
      }
    }
  }, [acceptedFiles])

  const getCategory = () => {
    const pathname = window.location.pathname

    if (pathname.split("/")[3] === "managers") {
      setCategory("Managers")
      setMode("Add")
      setTemplateDownloadLink(
        `${KINETIC_BACKEND_URL}/v1/location_setup/manager_template`
      )
    } else if (pathname.split("/")[1] === "locations") {
      if (pathname.includes("employees")) {
        setCategory("Employees")
        setMode("Add")
        setTemplateDownloadLink(
          `${KINETIC_BACKEND_URL}/v1/location_setup/worker_template`
        )
      } else if (pathname.includes("jobs")) {
        setCategory("Jobs")
        setMode("Add")
      } else {
        setCategory("Locations")
        setMode("Add")
        setTemplateDownloadLink(
          `${KINETIC_BACKEND_URL}/v1/location_setup/location_template`
        )
      }
    } else if (pathname.split("/")[3] === "devices") {
      setCategory("Devices")
      setMode("Move")
    }
  }

  async function setCompany(companyid) {
    setChooseLocation(false)
    await setCurrentCompany(companyid, dispatch)
    setTimeout(() => {
      setRender(true)
    }, 500)
  }

  const handleRemoveFile = useCallback(
    (fileName, click) => {
      const dt = new DataTransfer()
      const files = Array.from(inputRef.current.files)

      for (let file of files) {
        file.name !== fileName && dt.items.add(file)
      }

      inputRef.current.files = dt.files
      setStagedFile(null)
    },
    [inputRef]
  )

  const file = acceptedFiles.map((file, key) => (
    <>
      <div className="File-name" key={key + 1}>
        <p>{file.name}</p>
        <p className="Last-edited" key={key + 2}>
          {"Last Edited - "}
          <Moment format="MMM D YYYY HH:mm">
            {file?.lastModifiedDate?.toString()}
          </Moment>
        </p>
      </div>
      <div onClick={handleRemoveFile} key={key + 3}>
        <Icon iconClass={"X-mark"} />
      </div>
    </>
  ))

  const handleSubmit = () => {
    if (stagedFile) {
      csvUpload(
        setLoading,
        setErrorState,
        setMessage,
        history,
        category,
        location,
        params,
        stagedFile,
        autoAssign,
        companyUID,
        addToastMessage
      )
    }
  }

  const autoAssignDeviceToggle = () => {
    setAutoAssign(!autoAssign)
  }

  const uploadComponent = () => {
    return (
      <main className="container">
        {companyCategories.includes(category)
          ? passBreadCrumbs([
              {
                content: <Icon iconClass={"Skyline"} />,
                route: "/companies",
              },
              {
                content: companyMap && companyMap[companyUID],
                route: `/companies/${companyUID}/`,
              },
              { content: `${mode} ${category}`, route: "" },
            ])
          : passBreadCrumbs([
              {
                content: <Icon iconClass={"Skyline"} />,
                route: "/companies",
              },
              {
                content: companyMap && companyMap[companyUID],
                route: `/companies/${companyUID}/`,
              },
              {
                content: selectedLocation && selectedLocation,
                route: `/locations/${selectedLocation}/`,
              },
              { content: `${mode} ${category}`, route: "" },
            ])}
        <div className="main-content detail-page">
          <h1 className="main-heading">{companyMap[companyUID]}</h1>
          <h2 className="sub-heading">{`Import ${category} from CSV`}</h2>
          <div className="flex justify-center">
            <div className="w-50">
              <DropZone
                stagedFile={stagedFile}
                isDragAccept={isDragAccept}
                getRootProps={getRootProps}
                autoAssign={autoAssign}
                isDragActive={isDragActive}
                autoAssignDeviceToggle={autoAssignDeviceToggle}
                getInputProps={getInputProps}
                file={file}
                category={category}
                templateDownloadLink={templateDownloadLink}
                setLocation={setLocation}
                location={location}
                status={status}
                handleRemoveFile={handleRemoveFile}
              />
              {templateDownloadLink && (
                <a
                  href={templateDownloadLink}
                  className="text-underline text-lg mt-6"
                  download
                >
                  Download CSV Template
                </a>
              )}
            </div>
          </div>
          <div className="Csv-action-container">
            <BackButton label={category} backToRoute={backToRoutes[category]} />
            <div className="Csv-action-container-right">
              <div
                className={`button ${!stagedFile ? "disabled" : ""} ${
                  category === "Devices" ? (location ? "" : "disabled") : ""
                }`}
                onClick={handleSubmit}
              >
                Upload
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <Loading
            errorState={errorState}
            message={message}
            setLoading={setLoading}
          />
        )}
      </main>
    )
  }

  return (
    <div className="admin wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {mode} {category} • Kinetic
        </title>
      </Helmet>
      {render && uploadComponent()}
      {!render && chooseLocation && (
        <ListBox
          options={store.getState().admin.companyList}
          title={"Select a Company"}
          firstKey={"name"}
          secondKey={"uid"}
          callback={setCompany}
        />
      )}
      {!render && !chooseLocation && <Loading background="none" />}
    </div>
  )
}
